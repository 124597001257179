import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Beneficiary",
      content: (
        <>
          <p>
            A beneficiary is the person or entity for whose benefit the trust
            was created.
          </p>
        </>
      ),
    },
    {
      title: "Distribution",
      content: (
        <>
          <p>
            Distribution refers to the transfer of ownership of assets from the
            trust to the beneficiary.
          </p>
        </>
      ),
    },
    {
      title: "Fiduciary",
      content: (
        <>
          <p>
            A fiduciary is a person or entity that has a legal obligation to
            manage property on behalf of someone else. A fiduciary is held to a
            high standard of conduct and must carry out specific fiduciary
            duties. A fiduciary may be a personal representative (executor,
            administrator), trustee, guardian, or conservator.
          </p>
        </>
      ),
    },
    {
      title: "Income of the Trust",
      content: (
        <>
          <p>
            Everything earned by the principal of the trust, which can
            potentially be paid out to the beneficiary, is called income of the
            trust.
          </p>
        </>
      ),
    },
    {
      title: "Principal of the Trust",
      content: (
        <>
          <p>
            This refers to the assets owned by a trust. Principal of trust may
            also be referred to as the trust corpus.
          </p>
        </>
      ),
    },
    {
      title: "Trustee",
      content: (
        <>
          <p>
            A trustee is the person or entity that holds the trust property.
            They’re responsible for managing the trust for the beneficiary
            according to the terms of the trust.
          </p>
        </>
      ),
    },
    {
      title: "Trustor",
      content: (
        <>
          <p>
            This is the person or entity that created the trust. A trustor may
            also be referred to as a settlor or grantor.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust Administration Lawyer | Brierton, Jones & Jones"
        description="Brierton, Jones & Jones, LLP will connect you with the perfect San Diego trust administration lawyer for your needs. Learn more and schedule a consultation!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  YOU DON’T HAVE TO DO IT ALONE
                </div>
                <h1>San Diego Trust Administration Lawyer</h1>
                <p>
                  Being named a trustee for a family member or friend is a big
                  responsibility and can easily become overwhelming. Relieve the
                  burden by hiring a trust administration lawyer to help ensure
                  the trust is administered properly.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/5.0 Trust Administration/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>How Does a Trust Work?</h2>
                <p>
                  When someone creates a trust, they’re allowing a third party
                  (called a ‘trustee’) the right to hold and manage their assets
                  after they pass away.
                </p>
                <p>
                  The trustor (the person who creates the trust) does so to
                  benefit one or more beneficiaries. For example, a parent might
                  create a trust that regularly allocates funds to a child after
                  they turn 18 years old.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>What Are a Trustee’s Responsibilities?</h2>
                <p>
                  The trustee has a fiduciary duty to the trust’s beneficiaries.
                  In other words, a trustee legally responsible for competently
                  managing the assets under his or her control. If a trustee
                  breaches his or her fiduciary duty, the trustee can be held
                  liable for damages resulting from such mismanagement.
                </p>
                <p>
                  Depending on the trust, a trustee may be responsible, for
                  example, for marshaling (locating) assets, prudently investing
                  trust assets, paying taxes and other debts, keeping records,
                  maintaining real estate, making distributions, and much more.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  Even if you’re considering hiring a trust administration
                  lawyer to help administer a trust, understanding the terms is
                  helpful. Here are some of the most common:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={2} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="As a trustee, often the most responsible course of action is to hire an experienced trust administration lawyer to administer the trust. In San Diego, that’s Brierton, Jones & Jones, LLP." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/03 Trust Administration.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/03 Trust Administration.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
